<template>
  <ValidationObserver ref="form">
    <form @submit.prevent="validate">
      <h5 class="text-center">{{ $store.getters['languageMixin/getStringFromLanguage']('French Section') }}</h5>
      <div class="row">
        <div class="col-md-12 col-sm-12">

          <div class="row">
            <div class="col-md-12">
              <ValidationProvider
                vid="fr.group_id"
                rules="required"
                name="The Group"
                v-slot="{ passed, failed,errors }">
                <fg-select
                  name="group_id"
                  size="large"
                  filterable
                  clearable
                  :placeholder="$store.getters['languageMixin/getStringFromLanguage']('Group')"
                  :error="failed ? errors[0]: null"
                  :input-classes="'select-default'"
                  :label="$store.getters['languageMixin/getStringFromLanguage']('Group')"
                  :list="builderData.groupList"
                  :listItemLabel="'name'"
                  :listItemValue="'id'"
                  v-model="formData.group_id">
                </fg-select>
              </ValidationProvider>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <ValidationProvider
                vid="fr.name"
                rules="required"
                name="The Name"
                v-slot="{ passed, failed,errors }">
                <fg-input type="text"
                          :error="failed ? errors[0]: null"
                          :label="$store.getters['languageMixin/getStringFromLanguage']('Name')"
                          name="name"
                          @keyup="generateSomeFields"
                          fou
                          v-model="formData.name">
                </fg-input>
              </ValidationProvider>
            </div>
            <div class="col-md-6">
              <ValidationProvider
                vid="fr.ident"
                rules="required"
                name="The Ident"
                v-slot="{ passed, failed,errors }">
                <fg-input type="text"
                          :error="failed ? errors[0]: null"
                          :disabled="editMode === true"
                          :label="$store.getters['languageMixin/getStringFromLanguage']('Panel Ident')"
                          name="ident"
                          fou
                          v-model="formData.ident">
                </fg-input>
              </ValidationProvider>
            </div>
          </div>
          <fg-input type="text"
                    :label="$store.getters['languageMixin/getStringFromLanguage']('Main Heading')"
                    name="main_heading"
                    fou
                    v-model="formData.main_heading">
          </fg-input>
          <fg-input type="text"
                    :label="$store.getters['languageMixin/getStringFromLanguage']('Extra Heading')"
                    name="extra_heading"
                    fou
                    v-model="formData.extra_heading">
          </fg-input>
          <ValidationProvider
            vid="fr.google_ads_code"
            rules=""
            name="The Google ads code"
            v-slot="{ passed, failed,errors }">
            <fg-input type="text"
                      :error="failed ? errors[0]: null"
                      :label="$store.getters['languageMixin/getStringFromLanguage']('google_ads_code')"
                      name="google_ads_code"
                      fou
                      v-model="formData.google_ads_code">
            </fg-input>
          </ValidationProvider>
          <div class="form-group">
            <label>{{ $store.getters['languageMixin/getStringFromLanguage']('Content') }}</label>
            <editor
              v-model="formData.content"
              :api-key="editorKey"
              :init='editorConfig'
            />
          </div>
          <div class="form-group">
            <label>{{ $store.getters['languageMixin/getStringFromLanguage']('Image') }}</label>
            <el-tooltip placement="right" v-if="false">
              <div slot="content"></div>
              <span style="margin: 0 5px;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             class="bi bi-question-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path
                        d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                    </svg>
                    </span>
            </el-tooltip>
            <prime-uploader
              :preview-width="'200px'"
              :preview-height="'200px'"
              :elementNum="1"
              :maxFileSize="2000000"
              :isEditMode="editMode"
              v-model="formData.image"
            >
            </prime-uploader>
          </div>
          <div class="form-group">
            <label>{{ $store.getters['languageMixin/getStringFromLanguage']('icon') }}</label>
            <el-tooltip placement="right" v-if="false">
              <div slot="content"></div>
              <span style="margin: 0 5px;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             class="bi bi-question-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path
                        d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                    </svg>
                    </span>
            </el-tooltip>
            <prime-uploader
              :preview-width="'200px'"
              :preview-height="'200px'"
              :elementNum="1"
              :maxFileSize="2000000"
              :isEditMode="editMode"
              v-model="formData.image_icon"
            >
            </prime-uploader>
          </div>
          <ValidationProvider
            vid="fr.video_type"
            rules=""
            name="The Video Type"
            v-slot="{ passed, failed,errors }">
            <fg-select
              name="video_type"
              size="large"
              filterable
              clearable
              :placeholder="$store.getters['languageMixin/getStringFromLanguage']('Video Type')"
              :error="failed ? errors[0]: null"
              :input-classes="'select-default'"
              :label="$store.getters['languageMixin/getStringFromLanguage']('Video Type')"
              :list="builderData.VideoTypes"
              :listItemLabel="'label'"
              :listItemValue="'value'"
              v-model="formData.video_type">
            </fg-select>
          </ValidationProvider>
          <div class="form-group" v-if="formData.video_type === 'UPLOADED'">
            <label>{{ $store.getters['languageMixin/getStringFromLanguage']('Uploaded Video') }}</label>
            <el-tooltip placement="right" v-if="false">
              <div slot="content"></div>
              <span style="margin: 0 5px;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             class="bi bi-question-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path
                        d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                    </svg>
                    </span>
            </el-tooltip>
            <prime-video-uploader
              :preview-width="'200px'"
              :preview-height="'200px'"
              :maxFileSize="50000000"
              v-model="formData.uploaded_video">
            </prime-video-uploader>
          </div>
          <div class="form-group" v-if="formData.video_type === 'EMBEDDED'">
            <el-tooltip placement="right" v-if="false">
              <div slot="content"></div>
              <span style="margin: 0 5px;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             class="bi bi-question-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path
                        d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                    </svg>
                    </span>
            </el-tooltip>
            <ValidationProvider
              vid="fr.embedded_video"
              rules=""
              name="The Embedded Video"
              v-slot="{ passed, failed,errors }">
              <fg-input type="text"
                        :error="failed ? errors[0]: null"
                        :label="$store.getters['languageMixin/getStringFromLanguage']('Embedded Video')"
                        name="embedded_video"
                        fou
                        v-model="formData.embedded_video">
              </fg-input>
            </ValidationProvider>
            <div v-html="formData.embedded_video">
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <fg-select
                name="url_target"
                size="large"
                filterable
                clearable
                :placeholder="$store.getters['languageMixin/getStringFromLanguage']('Target')"
                :input-classes="'select-default'"
                :label="$store.getters['languageMixin/getStringFromLanguage']('Target')"
                :list="builderData.redirectionTargets"
                :listItemLabel="'label'"
                :listItemValue="'value'"
                v-model="formData.url_target">
              </fg-select>
            </div>
            <div class="col-md-6">
              <ValidationProvider
                vid="fr.redirection_type"
                name="Redirection Type"
                v-slot="{ passed, failed,errors }">
                <fg-select
                  name="redirection_type"
                  size="large"
                  filterable
                  clearable
                  :error="failed ? errors[0]: null"
                  :placeholder="$store.getters['languageMixin/getStringFromLanguage']('Redirection Type')"
                  :input-classes="'select-default'"
                  :label="$store.getters['languageMixin/getStringFromLanguage']('redirection Type')"
                  :list="builderData.redirectionTypes"
                  :listItemLabel="'label'"
                  :listItemValue="'value'"
                  v-model="formData.redirection_type">
                </fg-select>
              </ValidationProvider>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <fg-input type="text"
                        :label="$store.getters['languageMixin/getStringFromLanguage']('Destination Url')"
                        name="destination_url"
                        fou
                        v-model="formData.destination_url">
              </fg-input>
            </div>
            <div class="col-md-6">
              <fg-input type="text"
                        :label="$store.getters['languageMixin/getStringFromLanguage']('Destination Url Title')"
                        name="destination_url_title"
                        fou
                        v-model="formData.destination_url_title">
              </fg-input>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <label class="card-label">{{ $store.getters['languageMixin/getStringFromLanguage']('Date') }}</label>
              <fg-input v-model="formData.date"
                        value-format="YYYY-MM-DD HH:MM:SS"
                        type="datetime-local"
                        :placeholder="$store.getters['languageMixin/getStringFromLanguage']('Chose panel publish date')"
              >
              </fg-input>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <label class="card-label">{{ $store.getters['languageMixin/getStringFromLanguage']('Start Date') }}</label>
              <fg-input v-model="formData.start_date"
                        value-format="YYYY-MM-DD HH:MM:SS"
                        type="datetime-local"
                        :placeholder="$store.getters['languageMixin/getStringFromLanguage']('Chose panel publish date')"
              >
              </fg-input>
            </div>
            <div class="col-md-6">
              <label class="card-label">{{ $store.getters['languageMixin/getStringFromLanguage']('End Date') }}</label>
              <fg-input v-model="formData.end_date"
                        value-format="YYYY-MM-DD HH:MM:SS"
                        type="datetime-local"
                        :placeholder="$store.getters['languageMixin/getStringFromLanguage']('Chose panel expire date')"
              >

              </fg-input>
            </div>
          </div>


          <div class="form-group pt-4">
            <label>{{ $store.getters['languageMixin/getStringFromLanguage']('Is Active') }}</label>&nbsp;
            <l-switch v-model="formData.is_active">
              <i class="fa fa-check" slot="on"></i>
              <i class="fa fa-times" slot="off"></i>
            </l-switch>
          </div>
        </div>

      </div>
    </form>
  </ValidationObserver>
</template>
<script>
  import { extend } from "vee-validate";
  import { required, email } from "vee-validate/dist/rules";
  import {Option, Select, Tooltip} from "element-ui";
  import FgSelect from "@/components/Inputs/formGroupSelect";
  import FormGroupTextArea, {ImageUploader, PrimeUploader, PrimeVideoUploader, Switch as LSwitch} from "@/components";
  import Editor from '@tinymce/tinymce-vue'

  extend("required", required);
  extend("email", email);

  export default {
    components: {
      FormGroupTextArea,
      [Option.name]: Option,
      [Select.name]: Select,
      [Tooltip.name]: Tooltip,
      FgSelect,
      LSwitch,
      PrimeUploader,
      ImageUploader,
      PrimeVideoUploader,
      'editor': Editor
    },
    props: {
      lang: {
        type: String,
        default: 'fr'
      },
      editMode: {
        type: Boolean,
        default: false
      },
      builderData:{},
      formData:{
        type: Object,
        default() {
          return {
            group_id: "",
            name: "",
            ident: "",
            main_heading: "",
            extra_heading:"",
            content: "",
            image: "",
            image_icon:"",
            destination_url: "",
            destination_url_title: "",
            url_target: "",
            date: null,
            start_date: null,
            end_date: null,
            is_active: true,
            redirection_type: "",
            google_ads_code: "",
            video_type: '',
            uploaded_video: "",
            embedded_video: "",
          }
        }
      },
    },
    data() {
      return {
        editorConfig: this.getEditorConfig(),
        editorKey: process.env.VUE_APP_TINY_EDITOR_KEY,
        imagesExtensions: this.getImagesExtensions(),
        videoExtensions: this.getVideoExtensions(),
      };
    },
    mounted() {
      // this.formData.redirection_type = this.builderData.redirectionTypes[0].value;
    },
    methods: {
      validate() {
        return this.$refs.form.validate().then(res => {
          if (res){
            this.$emit('submit-step', this.formData, this.lang)
          }else{
            this.$emit('on-validated', res)
          }
          return res
        });
      },
      generateSomeFields() {
        if(this.editMode){
          return;
        }
        let newUrl = '';
        if (this.formData.name) {
          newUrl = this.formData.name.replace(/\s+/g, "-").toLowerCase();
          newUrl = this.removeAccents(newUrl);
          newUrl = newUrl.replace(/[^ءأ-يa-zA-Z0-9-]/g, "");
          newUrl = newUrl.replace(/([-]+)/g, "_");
        }

        this.formData.ident = newUrl;
      },
      vueDate() {
        this.formData.start_date = (this.formData.start_date) ? this.formData.start_date.replace(' ', 'T') : this.formData.start_date;
        this.formData.end_date = (this.formData.end_date) ? this.formData.end_date.replace(' ', 'T') : this.formData.end_date;
      },
      laravelDate() {
        this.formData.start_date = (this.formData.start_date) ? this.formData.start_date.replace('T', ' ') : this.formData.start_date;
        this.formData.end_date = (this.formData.end_date) ? this.formData.end_date.replace('T', ' ') : this.formData.end_date;
      },
    }
  }
</script>
<style>
</style>
