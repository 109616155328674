<template>

  <div class="row" ref="groupForm">

    <div class="col-xs-8 offset-xs-2 col-md-8 offset-md-2 col-sm-12">
      <ValidationObserver v-slot="{ handleSubmit }" ref="formValidator">

        <card>
          <div slot="header">
            <h4 class="card-title">
              {{ formTitle }}
            </h4>
          </div>

          <div class="card-body">
            <ValidationProvider
              vid="name"
              rules="required"
              name="The name"
              v-slot="{ passed, failed,errors }">
              <fg-input type="text"
                        :error="failed ? errors[0]: null"
                        :label="$store.getters['languageMixin/getStringFromLanguage']('Group Name')"
                        name="name"
                        @keyup="generateSomIdent"
                        fou
                        v-model="formData.name">
              </fg-input>
            </ValidationProvider>

            <ValidationProvider
              vid="ident"
              rules="required"
              name="The ident"
              v-slot="{ passed, failed,errors }">
              <fg-input type="text"
                        :error="failed ? errors[0]: null"
                        :disabled="editMode === true"
                        :label="$store.getters['languageMixin/getStringFromLanguage']('Group Ident')"
                        name="name"
                        fou
                        v-model="formData.ident">
              </fg-input>
            </ValidationProvider>

          </div>

          <div class="card-footer text-right">
            <l-button :disabled="entityNotFoundError || submitting" @click.prevent="handleSubmit(submit)"
                      nativeType="submit"
                      type="info" wide>{{ $store.getters['languageMixin/getStringFromLanguage']('Submit') }}
            </l-button>
            <l-button @click="$router.push('/panel-groups/list')" type="danger" wide>{{ $store.getters['languageMixin/getStringFromLanguage']('Cancel') }}
            </l-button>
          </div>
        </card>


      </ValidationObserver>

    </div>

  </div>
</template>
<script>
import {extend} from "vee-validate";
import FgSelect from "@/components/Inputs/formGroupSelect";

extend("required", {
  message: "{_field_} is required"
});

export default {
  components:{
    FgSelect,
  },
  data() {
    return {
      editMode: false,
      loader: {},
      id: undefined,

      formTitle: "",
      entityNotFoundError: false,
      submitting: false,

      formData: {
        name: "",
        ident: "",
      },
    };
  },
  mounted() {

    this.loader = this.$loading.show({
      container: this.$refs.groupForm
    });

    this.id = this.$route.params['id'];

    if (this.id !== undefined) {
      this.editMode = true;
      this.formTitle = this.$store.getters['languageMixin/getStringFromLanguage']("Edit Group");
      this.getGroup();
    } else {
      this.editMode = false;
      this.formTitle = this.$store.getters['languageMixin/getStringFromLanguage']("Add Group");
      this.loader.hide();
    }
  },


  methods: {
    generateSomIdent() {
      if (!this.editMode) {
        let newUrl = '';
        if (this.formData.name) {
          newUrl = this.formData.name.replace(/\s+/g, "-").toLowerCase();
          newUrl = newUrl.replace(/[^ءأ-يa-zA-Z0-9-]/g, "");
          newUrl = newUrl.replace(/([-]+)/g, "_");
        }
        this.formData.ident = newUrl;
      }
    },
    getGroup() {
      let data = {
        'id': this.id,
      };
      this.axios.post("panel-groups/get", data).then((response) => {
        this.formData = response.data;
        this.loader.hide();
      }).catch((error) => {
        if (error.response.status === 404) {
          this.entityNotFoundError = true;
          this.$notify({
            message: this.$store.getters['languageMixin/getStringFromLanguage']("Group Not Found"),
            timeout: 2000,
            type: 'danger'
          });
          this.loader.hide();
        } else {
          console.error(error);
        }
      })
    },
    submit() {
      let request;
      let successMessage;
      this.submitting = true;

      if (this.editMode === true) {
        request = this.axios.put("panel-groups/update/" + this.id, this.formData);
        successMessage = this.$store.getters['languageMixin/getStringFromLanguage']("Group Updated Successfully");
      } else {
        request = this.axios.post("panel-groups/create", this.formData);
        successMessage = this.$store.getters['languageMixin/getStringFromLanguage']("Group Added Successfully");
      } 

      request.then((response) => {
        this.$notify({
          message: successMessage,
          timeout: 1000,
          type: 'success'
        })
        this.$router.push("/panel-groups/list");
      }).catch((error) => {
        if (error.response.status === 422) {
          this.$refs.formValidator.setErrors(error.response.data.errors);
        } else {

        }
      }).finally(() => {
        this.submitting = false;
      })
    }

  }
}
</script>

<style>
</style>
