<template>

  <div class="row" ref="partnerForm">

    <div class="col-xs-12 col-md-12 col-sm-12">
      <ValidationObserver v-slot="{ handleSubmit }" ref="formValidator">

        <card>
          <div slot="header">
            <h4 class="card-title">
              {{ formTitle }}
            </h4>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-md-10">
                <ValidationProvider
                  vid="title"
                  rules="required"
                  name="The Title"
                  v-slot="{ passed, failed,errors }">
                  <fg-input type="text"
                            :error="failed ? errors[0]: null"
                            :label="$store.getters['languageMixin/getStringFromLanguage']('Title (Email Subject)')"
                            name="title"
                            fou
                            v-model="formData.title">
                  </fg-input>
                </ValidationProvider>
              </div>
              <div class="col-md-2">
                <ValidationProvider
                  vid="numero"
                  rules="required"
                  name="The numero"
                  v-slot="{ passed, failed,errors }">
                  <fg-input type="text"
                            :error="failed ? errors[0]: null"
                            :label="$store.getters['languageMixin/getStringFromLanguage']('Newsletter Number')"
                            name="numero"
                            fou
                            v-model="formData.numero">
                  </fg-input>
                </ValidationProvider>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <ValidationProvider
                  vid="fr.list_id"
                  rules="required"
                  name="The Contact List"
                  v-slot="{ passed, failed,errors }">
                  <fg-select
                    name="list_id"
                    size="large"
                    filterable
                    clearable
                    :placeholder="$store.getters['languageMixin/getStringFromLanguage']('Select a list')"
                    :error="failed ? errors[0]: null"
                    :input-classes="'select-default'"
                    :label="$store.getters['languageMixin/getStringFromLanguage']('Select a list')"
                    :list="contactLists"
                    :listItemLabel="'name'"
                    :listItemValue="'id'"
                    v-model="formData.list_id">
                  </fg-select>
                </ValidationProvider>
              </div>
              <div class="col-md-6">
                <label>{{ $store.getters['languageMixin/getStringFromLanguage']('Schedule send') }}</label>
                <fg-input v-model="formData.schedule"
                          value-format="YYYY-MM-DD HH:MM:SS"
                          type="datetime-local"
                          :placeholder="$store.getters['languageMixin/getStringFromLanguage']('Schedule (if you leave it empty the email will be sent after 3 minutes)')"
                >
                </fg-input>
              </div>
            </div>


            <div class="form-group">
              <label>{{ $store.getters['languageMixin/getStringFromLanguage']('Header Image') }}</label>
              <el-tooltip placement="right" v-if="false">
                <div slot="content"></div>
                <span style="margin: 0 5px;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             class="bi bi-question-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path
                        d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                    </svg>
                    </span>
              </el-tooltip>
              <prime-uploader
                :preview-width="'200px'"
                :preview-height="'200px'"
                :elementNum="1"
                :maxFileSize="2000000"
                :isEditMode="editMode"
                v-model="formData.header_image"
              >
              </prime-uploader>
            </div>
<!--            //Session-->
            <div class="row">
              <div class="col-md-12">
                <ValidationProvider
                  vid="fr.main_session_id"
                  rules=""
                  name="The Session"
                  v-slot="{ passed, failed,errors }">
                  <fg-select
                    name="main_session_id"
                    size="large"
                    filterable
                    clearable
                    :placeholder="$store.getters['languageMixin/getStringFromLanguage']('Main Session')"
                    :error="failed ? errors[0]: null"
                    :input-classes="'select-default'"
                    :label="$store.getters['languageMixin/getStringFromLanguage']('Main Session')"
                    :list="builderData.trainingSessionList"
                    :listItemLabel="'title'"
                    :listItemValue="'id'"
                    v-model="formData.main_session_id">
                  </fg-select>
                </ValidationProvider>
              </div>
            </div>


            <!--            //Book-->
            <div class="row">
              <div class="col-md-12">
                <ValidationProvider
                  vid="fr.book_id"
                  rules=""
                  name="The Book"
                  v-slot="{ passed, failed,errors }">
                  <fg-select
                    name="book_id"
                    size="large"
                    filterable
                    clearable
                    :placeholder="$store.getters['languageMixin/getStringFromLanguage']('Book')"
                    :error="failed ? errors[0]: null"
                    :input-classes="'select-default'"
                    :label="$store.getters['languageMixin/getStringFromLanguage']('Book')"
                    :list="builderData.bookList"
                    :listItemLabel="'title'"
                    :listItemValue="'id'"
                    v-model="formData.book_id">
                  </fg-select>
                </ValidationProvider>
              </div>
            </div>

            <!--            //Publication Category-->
            <div class="row">
              <div class="col-md-12">
                <ValidationProvider
                  vid="fr.publication_category_id"
                  rules=""
                  name="The Publication Category"
                  v-slot="{ passed, failed,errors }">
                  <fg-select
                    name="publication_category_id"
                    size="large"
                    filterable
                    clearable
                    :placeholder="$store.getters['languageMixin/getStringFromLanguage']('Publication Category')"
                    :error="failed ? errors[0]: null"
                    :input-classes="'select-default'"
                    :label="$store.getters['languageMixin/getStringFromLanguage']('Publication Category')"
                    :list="builderData.publicationCategoryList"
                    :listItemLabel="'name'"
                    :listItemValue="'id'"
                    @change="getBuilderData"
                    v-model="formData.publication_category_id">
                  </fg-select>
                </ValidationProvider>
              </div>
            </div>

            <!--            //Select Multi Publications-->
            <div class="row">
              <div class="col-md-12">
                <ValidationProvider
                  vid="fr.publication_ids"
                  rules=""
                  name="The Publications"
                  v-slot="{ passed, failed,errors }">
                  <fg-select
                    name="publication_ids"
                    size="large"
                    filterable
                    clearable
                    multiple
                    :placeholder="$store.getters['languageMixin/getStringFromLanguage']('Publications')"
                    :error="failed ? errors[0]: null"
                    :input-classes="'select-default'"
                    :label="$store.getters['languageMixin/getStringFromLanguage']('Publications')"
                    :list="builderData.publicationList"
                    :listItemLabel="'title'"
                    :listItemValue="'id'"
                    v-model="formData.publication_ids">
                  </fg-select>
                </ValidationProvider>
              </div>
            </div>

<!--            //NewsCategory-->
            <div class="row">
              <div class="col-md-12">
                <ValidationProvider
                  vid="fr.news_category_id"
                  rules=""
                  name="The News Category"
                  v-slot="{ passed, failed,errors }">
                  <fg-select
                    name="news_category_id"
                    size="large"
                    filterable
                    clearable
                    :placeholder="$store.getters['languageMixin/getStringFromLanguage']('News Category')"
                    :error="failed ? errors[0]: null"
                    :input-classes="'select-default'"
                    :label="$store.getters['languageMixin/getStringFromLanguage']('News Category')"
                    :list="builderData.newsCategoryList"
                    :listItemLabel="'name'"
                    :listItemValue="'id'"
                    @change="getBuilderData"
                    v-model="formData.news_category_id">
                  </fg-select>
                </ValidationProvider>
              </div>
            </div>

<!--            //Select Multi News-->
            <div class="row">
              <div class="col-md-12">
                <ValidationProvider
                  vid="fr.news_ids"
                  rules=""
                  name="The News"
                  v-slot="{ passed, failed,errors }">
                  <fg-select
                    name="news_ids"
                    size="large"
                    filterable
                    clearable
                    multiple
                    :placeholder="$store.getters['languageMixin/getStringFromLanguage']('News')"
                    :error="failed ? errors[0]: null"
                    :input-classes="'select-default'"
                    :label="$store.getters['languageMixin/getStringFromLanguage']('News')"
                    :list="builderData.newsList"
                    :listItemLabel="'title'"
                    :listItemValue="'id'"
                    v-model="formData.news_ids">
                  </fg-select>
                </ValidationProvider>
              </div>
            </div>




          </div>

          <div class="card-footer text-right">
            <l-button :disabled="entityNotFoundError || submitting" @click.prevent="handleSubmit(submit)"
                      nativeType="submit"
                      type="info" wide>{{ $store.getters['languageMixin/getStringFromLanguage']('Submit') }}
            </l-button>
            <l-button @click="$router.push('/newsletter-templates/send')" type="danger" wide>{{ $store.getters['languageMixin/getStringFromLanguage']('Cancel') }}
            </l-button>
          </div>
        </card>


      </ValidationObserver>

    </div>

<!--    <NewsletterTemplate id="newsletterTemplate" :formData="formData" :builderData="builderData"></NewsletterTemplate>-->
    <NewNewsletterTemplate id="newsletterTemplate" :formData="formData" :builderData="builderData"></NewNewsletterTemplate>
  </div>
</template>
<script>
import {extend} from "vee-validate";
import FgSelect from "@/components/Inputs/formGroupSelect";
import FormGroupTextArea, {ImageUploader, PrimeUploader, PrimeVideoUploader, Switch as LSwitch} from "@/components";
import NewsletterTemplate from "../emailTemplates/NewsletterTemplate";
import NewNewsletterTemplate from "../emailTemplates/NewNewsletterTemplate";

extend("required", {
  message: "{_field_} is required"
});

export default {
  components:{
    NewNewsletterTemplate,
    NewsletterTemplate,
    LSwitch,
    FgSelect,
    PrimeUploader,
  },
  data() {
    return {
      editMode: false,
      loader: {},
      id: undefined,

      formTitle: "",
      entityNotFoundError: false,
      submitting: false,
      contactLists: [],
      formData: {
        title: "",
        header_image: "",
        news_ids: [],
        publication_ids: [],
        session_ids: [],
        upcoming_lectures: "",
        main_news_id: [],
        main_news_image: "",
        schedule: '',
        list_id: '',
        agendas: [],
        numero: '',


        main_session_id: '',
        book_id: '',
        news_category_id: '',
        publication_category_id: '',
        newsletterTemplate: '',
      },
      builderData: {
        trainingSessionList: [],
        newsList: [],
        publicationList: [],
        publicationCategoryList: [],
        newsCategoryList: [],
        bookList: [],
        newsletterNumber: 0,
      },
    };
  },

  mounted() {
    this.loader = this.$loading.show({
      container: this.$refs.partnerForm
    });

    this.getBuilderData();
    this.getAllContactList();

    this.id = this.$route.params['id'];
    if (this.id !== undefined) {
      this.editMode = true;
      this.formTitle = this.$store.getters['languageMixin/getStringFromLanguage']("Edit Template");
      this.getItem();
    } else {
      this.editMode = false;
      this.formTitle = this.$store.getters['languageMixin/getStringFromLanguage']("Add Template");
      this.loader.hide();
    }

  },


  methods: {
    getBuilderData() {
      let data = {
        news_category_id: this.formData.news_category_id,
        publication_category_id: this.formData.publication_category_id
      }
      this.axios.post("newsletter-templates/builder", data).then((response) => {
        this.formData.numero = response.data.newsletterNumber;
        this.builderData.newsList = response.data.news;
        this.builderData.publicationList = response.data.publications;
        this.builderData.publicationCategoryList = response.data.publicationCategories;
        this.builderData.newsCategoryList = response.data.newsCategories;
        this.builderData.bookList = response.data.books;
        this.builderData.trainingSessionList = response.data.trainingSessions
          .map(obj => ({...obj, title: obj.training.title + ' ( ' + obj.days + ' )'}));

      }).catch((error) => {
        console.error(error);
      })
    },
    getAllContactList() {
      this.axios.get("newsletter-templates/get-all-lists").then((response) => {
        this.contactLists = response.data;
        this.contactLists.unshift({id: 'all', name: 'All Contacts'})
      }).catch((error) => {
        console.error(error);
        this.$notify({
          message: "couldn't get contact lists",
          timeout: 6000,
          type: 'danger'
        })
      })
    },
    getItem() {
      let data = {
        'id': this.id,
      };
      this.axios.post("newsletter-templates/get", data).then((response) => {
        this.formData = response.data;
        this.loader.hide();
      }).catch((error) => {
        if (error.response.status === 404) {
          this.entityNotFoundError = true;
          this.$notify({
            message: this.$store.getters['languageMixin/getStringFromLanguage']("Template Not Found"),
            timeout: 2000,
            type: 'danger'
          });
          this.loader.hide();
        } else {
          console.error(error);
        }
      })
    },
    submit() {
      let request;
      let successMessage;
      this.submitting = true;

      this.formData.html = document.getElementById('newsletterTemplate').innerHTML;
      if (this.editMode === true) {
        request = this.axios.put("newsletter-templates/update/" + this.formData.campaign_id, this.formData);
        successMessage = this.$store.getters['languageMixin/getStringFromLanguage']("Template Updated Successfully");
      } else {
        request = this.axios.post("newsletter-templates/create", this.formData);
        successMessage = this.$store.getters['languageMixin/getStringFromLanguage']("Template Added Successfully");
      }

      request.then((response) => {
        this.$notify({
          message: successMessage,
          timeout: 1000,
          type: 'success'
        })
        // this.$router.push("/newsletter-templates/list");
      }).catch((error) => {
        if (error.response.status === 422) {
          this.$refs.formValidator.setErrors(error.response.data.errors);
        } else {
          this.$notify({
            message: error.response.data.message,
            timeout: 3000,
            type: 'danger'
          })
        }
      }).finally(() => {
        this.submitting = false;
      })
    },
    addNewItem(listItems, initObject) {
      listItems.push(initObject);
    },
    removeItem(listItems, index) {
      listItems.splice(index, 1);
    },

  }
}
</script>

<style>
</style>
